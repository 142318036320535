
import { defineComponent, reactive } from "vue";
import BaseOrderCard from "@/app/core/components/cards/BaseOrderCard.vue";
import { OrderStatus } from "@/app/core/enums/OrderStatus";

export default defineComponent({
  name: "MyRatings",
  components: { BaseOrderCard },
  setup() {
    let feedback = reactive({
      received: 3,
      provided: 4,
    });
    return {
      orderStatus: OrderStatus,
    };
  },
});
